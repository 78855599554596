import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter, get, isEmpty, min, uniq } from "lodash";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { AddShoppingCart, Info } from "@mui/icons-material";
import Slider from "react-slick";
import { makeStyles } from "../../AppContainer/mui-theme";
import "../slick-theme.css";
import Image from "../../image";
import { mediaTypes } from "../../../utils/media";
import NoImageRenderer from "../../no-image-renderer";
import { navigateTo } from "../../../utils/location";
import {
  setFeaturedProductToByInOrder,
  startNewOrder,
} from "../../../store/order/actions";
import { openNewOrPrevOrderModal } from "../../../store/app/actions";
import { getFeaturedItems } from "../../../store/selectors";

const FeaturedWidget = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const { appStyles, widget, pageContext } = props;
  const featuredItems = useSelector((state) => getFeaturedItems(state, props));
  const order = useSelector((state) => state.order);

  const sliderRef = useRef(null);
  const dotsRef = useRef(null);

  const handleAddFeaturedItemToOrder = (item) => {
    const { orderItems } = order;

    const skipServingOptionsPage = get(
      appStyles,
      "skipServingOptionsPage",
      false
    );

    const isDuringOrder = !isEmpty(orderItems);

    const navigateToNextPage = () => {
      navigateTo(
        skipServingOptionsPage || pageContext.servingOptions.length < 2
          ? "/find-location"
          : "/serving-options"
      );
    };

    if (isDuringOrder) {
      dispatch(openNewOrPrevOrderModal());
    } else {
      dispatch(startNewOrder());
      navigateToNextPage();
    }
    dispatch(setFeaturedProductToByInOrder(item.id));
  };

  const getPrice = (item) => {
    if (item.price && item.price > 0.01) {
      return Number(item.price).toFixed(2);
    }

    const servingOptionsPrices = [];

    for (let option of item.servingOptions) {
      for (let sizeOption of option.sizeOptions) {
        if (sizeOption.price) {
          servingOptionsPrices.push(sizeOption.price);
        }
      }
    }

    return (servingOptionsPrices && servingOptionsPrices.length > 0) ? Number(min(uniq(servingOptionsPrices))).toFixed(2) : undefined;
  }

  let isLastDot = false;

  const settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 1.2, // to show a part of next slide
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    focusOnSelect: true,
    appendDots: (dots) => {
      // as slidesToShow is 2.2, we need to manually set active dot for last slide
      if (isLastDot) {
        dotsRef.current &&
          (dotsRef.current.children[featuredItems.length - 2].className =
            "slick-active");
      } else {
        dotsRef.current &&
          (dotsRef.current.children[featuredItems.length - 2].className = "");
      }
      return (
        <div>
          <ul ref={dotsRef} style={{ margin: 0, padding: 0 }}>
            {" "}
            {dots}{" "}
          </ul>
        </div>
      );
    },
    beforeChange: (_, next) =>
      // next slide index for last is n.9, because of slidesToShow is 2.2
      (isLastDot = Math.round(next) === featuredItems.length - 2),
  };

  const renderCarusleMenuItem = (item, index) => {
    const imageSizesProps = {
      sizes: "200px",
      wrapperStyle: {
        height: 200,
        width: 200,
      },
    };

    return (
      <div
        key={index}
        style={{
          height: "auto",
          margin: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {get(item, "imageKey") ? (
          <Image
            mediaKey={item.imageKey}
            mediaType={mediaTypes.menuItem}
            imagePreview={item.imagePreview}
            {...imageSizesProps}
          />
        ) : (
          <NoImageRenderer {...imageSizesProps} appStyles={props.appStyles} />
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "200px",
            marginTop: 6,
            fontSize: "0.8rem",
            minHeight: "48px",
          }}
        >
          <p style={{ margin: 0, textAlign: "center" }}>{item.name}</p>
        </div>
      </div>
    );
  };

  const sliderContent = filter(featuredItems, item => item).map((item, index) => (
    <div>
      <Paper elevation={3} sx={{ margin: "10px 5px", position: "relative" }}>
        <Tooltip
          title={item?.description}
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: "0.8em",
                width: "100px",
              },
            },
            popper: {
              sx: {
                [`&.${
                  tooltipClasses.popper
                }[data-popper-placement*="bottom"] .${
                  tooltipClasses.tooltip
                }`]: {
                  marginTop: "5px",
                },
                [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${
                  tooltipClasses.tooltip
                }`]: {
                  marginBottom: "5px",
                },
                [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${
                  tooltipClasses.tooltip
                }`]: {
                  marginLeft: "5px",
                },
                [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${
                  tooltipClasses.tooltip
                }`]: {
                  marginRight: "5px",
                },
              },
            },
          }}
        >
          <Info
            sx={{
              width: "20px",
              position: "absolute",
              zIndex: "100",
              left: "85%",
              opacity: 0.6,
            }}
            color="primary"
          />
        </Tooltip>
        <Grid
          container
          sx={{ minHeight: "120px" }}
          display={"flex"}
          justifyContent={"center"}
        >
          <Grid item>{renderCarusleMenuItem(item)}</Grid>
        </Grid>
        <Divider sx={{ margin: "0 10px" }} />
        <Grid container sx={{ padding: "10px" }}>
          <Grid item xs={6}>
            {getPrice(item) && <Typography variant="h5" style={{ margin: 0 }}>{`${
              pageContext.business.currencySymbol
            }${getPrice(item)}`}</Typography>}
          </Grid>
          <Grid
            item
            xs={6}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              startIcon={<AddShoppingCart />}
              onClick={() => handleAddFeaturedItemToOrder(item)}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  ));

  return (featuredItems && !isEmpty(featuredItems)) && (
    <Card style={{ borderRadius: appStyles.cardBorderRadius }}>
     
      {(!isEmpty(widget.title) ||
        !isEmpty(widget.subtitle) ||
        !isEmpty(widget.content)) && (
        <CardContent className={classes.content}>
          {!isEmpty(widget.title) && (
            <Typography gutterBottom variant="h2" component="h2">
              {widget.title}
            </Typography>
          )}
          {!isEmpty(widget.subtitle) && (
            <Typography gutterBottom variant="caption" component="h3">
              {widget.subtitle}
            </Typography>
          )}
          {!isEmpty(widget.content) && (
            <Typography variant="body1" component="p">
              {widget.content}
            </Typography>
          )}
        </CardContent>
      )}
       <Slider ref={sliderRef} {...settings} className={classes.featuredSlider}>
        {sliderContent}
      </Slider>
    </Card>
  );
};

const desktopCardPromotionWidth = 376;

const useStyles = makeStyles()((theme) => ({
  featuredSlider: {
    height: `calc(100vw + ${theme.spacing(2)})`,
    width: `calc(100vw - ${theme.spacing(4)})`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${desktopCardPromotionWidth}px - ${theme.spacing(4)})`,
    },
  },
}));

export default FeaturedWidget;
